import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import Swal from "sweetalert2";

import MaskedTextField from "../../components/maskedField";
import LoadingForm from "../../components/loadingForm";
import Button from "../../components/button";
import ContainerDisabled from "../../components/containerDisabled";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import {
  Container,
  ContainerInformations,
  Content,
  ContentInfos,
  ContentTitle,
  Form,
  Informations,
  LogoTennisClinic,
  LogoTennisClinicMobile,
  TextClinic,
  TextInfo,
  TextInfoTwo,
} from "./styles";

import LogoTennis from "../../assets/clinica_de_tenis/logo_tennis_clinic.svg";
import LogoTennisMobile from "../../assets/clinica_de_tenis/logo_tennis_clinic_mobile.svg";

const tennisLevel = [
  "Iniciante: 09h15 - 10h15",
  "Intermediário: 10h15 - 11h15",
  "Avançado: 11h15 - 12h15",
];

const sizeTshirt = ["PP", "P", "M", "G", "GG"];

const TennisClinic = () => {
  const { control, handleSubmit, register } = useForm();

  const [loading, setLoading] = useState(false);
  const [available, setAvailabe] = useState(true);
  const [level, setLevel] = useState([]);

  const checkLenght = (value, length) => {
    return value.replace(/[^0-9]/g, "").length === length;
  };

  const onSubmit = async (formData) => {
    setLoading(true);

    const reloadPage = () => window.location.reload();

    try {
      const response = await fetch(
        "https://api.caiobasc.com.br/landing-page/form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            eventName: "Clínica de Tênis",
          }),
        }
      );

      const data = await response.json();

      if (!data.success) {
        Swal.fire({
          title: "Erro ao enviar formulário",
          html: data.message,
          icon: "error",
        }).then(reloadPage);
        return;
      }

      Swal.fire({
        title: "Formulário enviado com sucesso!",
        icon: "success",
      }).then(reloadPage);
    } catch (error) {
      Swal.fire({
        title: "Erro ao enviar formulário",
        html: "Não foi possível enviar o formulário. Tente novamente mais tarde.",
        icon: "error",
      }).then(reloadPage);
    } finally {
      setLoading(false);
    }
  };

  const checkSlots = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://api.caiobasc.com.br/check-slots", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ eventName: "Clínica de Tênis" }),
      });

      const data = await response.json();

      const levels = tennisLevel.map((level, index) => ({
        tennisLevel: level,
        registration_closed: data[index]?.registration_closed || false,
      }));

      const availableLevels = levels
        .filter((item) => !item.registration_closed)
        .map((item) => item.tennisLevel);

      if (availableLevels.length === 0) {
        setAvailabe(false);
        return;
      }

      setLevel(availableLevels);
    } catch (error) {
      console.error("Erro ao checar vagas: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkSlots();
  }, []);

  return (
    <Container>
      {loading && <LoadingForm />}

      <ContainerInformations>
        <Informations>
          <ContentTitle>
            <LogoTennisClinic src={LogoTennis} alt="logo_clinica_de_tenis" />
            <LogoTennisClinicMobile
              src={LogoTennisMobile}
              alt="logo_clinica_de_tenis"
            />
            <TextClinic>
              Inscrição para a<br />{" "}
              <strong style={{ fontSize: 38, fontWeight: 900 }}>
                CLÍNICA DE TÊNIS
              </strong>
            </TextClinic>
          </ContentTitle>

          <ContentInfos>
            <TextInfo>
              <strong>Data: </strong>15, 16 e 18/07
            </TextInfo>
            <TextInfo>
              <strong>Valor: </strong>R$ 750,00
            </TextInfo>
            <TextInfo style={{ marginBottom: 14 }}>
              <strong>Horário: </strong>Conforme seu nível
            </TextInfo>

            <TextInfoTwo style={{ marginBottom: 20 }}>
              Sua inscrição só estará confirmada após o pagamento e envio do
              comprovante!
            </TextInfoTwo>

            <TextInfo>
              <strong>Dados bancários:</strong>
            </TextInfo>
            <TextInfo>Banco ITAÚ</TextInfo>
            <TextInfo>
              <strong>Ag: </strong>2937
            </TextInfo>
            <TextInfo>
              <strong>C/C: </strong>21794-7
            </TextInfo>
            <TextInfo>
              <strong>CNPJ: </strong>30.799.735/0001-98
            </TextInfo>
            <TextInfo>
              <strong>Chaves PIX: </strong>30799735000198
            </TextInfo>
            <TextInfo style={{ marginBottom: 20 }}>
              <strong>TTC Soccer LTDA</strong>
            </TextInfo>

            <TextInfoTwo>
              <strong style={{ fontSize: 19 }}>
                Enviar comprovante para: mayara@caiobasc.com
              </strong>
            </TextInfoTwo>
            <TextInfo style={{ marginTop: 16 }}>
              <strong style={{ fontSize: 38, fontWeight: 900 }}>
                VAGAS LIMITADAS!!!
              </strong>
            </TextInfo>
          </ContentInfos>
        </Informations>

        {available === false ? (
          <ContainerDisabled />
        ) : (
          <Content>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                required
                label="Nome Completo"
                variant="standard"
                fullWidth
                sx={{ mb: 2 }}
                InputLabelProps={{
                  style: { marginLeft: 14 },
                }}
                inputProps={{
                  style: { paddingLeft: 14 },
                }}
                {...register("name")}
              />

              <TextField
                label="Data de Nascimento"
                required
                variant="standard"
                type="date"
                fullWidth
                sx={{ mb: 2 }}
                InputLabelProps={{
                  shrink: true,
                  style: { marginLeft: 14 },
                }}
                inputProps={{
                  style: { paddingLeft: 14 },
                }}
                {...register("birthdate")}
              />

              <Controller
                control={control}
                name="phone"
                rules={{
                  required: true,
                  validate: (value) =>
                    checkLenght(value, 11) || "Telefone incompleto",
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <MaskedTextField
                    mask="(99) 99999-9999"
                    label="Telefone"
                    variant="standard"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!error}
                    helperText={error ? error.message : null}
                    InputLabelProps={{
                      style: { marginLeft: 14 },
                    }}
                    inputProps={{
                      style: { paddingLeft: 14 },
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label="Email"
                    required
                    variant="standard"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    sx={{ mb: 2 }}
                    InputLabelProps={{
                      style: { marginLeft: 14 },
                    }}
                    inputProps={{
                      style: { paddingLeft: 14 },
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="tennisLevel"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth sx={{ mb: 2 }} required>
                    <InputLabel>Nível do Tênis</InputLabel>
                    <Select
                      name="Nivel do tenis"
                      variant="standard"
                      value={value}
                      onChange={onChange}
                      label="Nível do Tênis"
                      style={{ paddingLeft: 14 }}
                    >
                      {level.map((level) => (
                        <MenuItem key={level} value={level}>
                          {level}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="sizeTshirt"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth sx={{ mb: 2 }} required>
                    <InputLabel>Tamanho da Camisa</InputLabel>
                    <Select
                      name="Tamanho da Camisa"
                      value={value}
                      variant="standard"
                      onChange={onChange}
                      label="Tamanho da Camisa"
                      style={{ paddingLeft: 14 }}
                    >
                      {sizeTshirt.map((size) => (
                        <MenuItem key={size} value={size}>
                          {size}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Button disabled={true}>Inscrições Encerradas</Button>
            </Form>
          </Content>
        )}
      </ContainerInformations>
    </Container>
  );
};

export default TennisClinic;
