import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
`;

export const Message = styled.label`
  font-size: 20px;
  font-family: "GothamPro", sans-serif;
`;

export const WhatsAppLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #128c7e;
  text-decoration: none;
  font-size: 16px;
  font-family: "GothamPro", sans-serif;
  flex-direction: column;
  gap: 10px;
`;
