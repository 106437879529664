import styled from "styled-components";

import Background from "../../assets/clinica_de_tenis/background.webp";
import BackgroundMobile from "../../assets/clinica_de_tenis/background_mobile.webp";

export const Container = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px 20px;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1230px) {
    padding: 20px 20px;
  }

  @media (max-width: 1090px) {
    height: 100%;
  }

  @media (max-width: 475px) {
    background-image: url(${BackgroundMobile});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const ContainerInformations = styled.section`
  width: 1200px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1230px) {
    width: 100%;
  }

  @media (max-width: 1090px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Content = styled.div`
  max-width: 469px;
  width: 100%;
  height: 528px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Informations = styled.div`
  max-width: 636px;
  width: 100%;
  height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    height: 100%;
  }
`;

export const ContentTitle = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    height: 100%;
    text-align: center;
    gap: 18px;
  }
`;

export const LogoTennisClinic = styled.img`
  width: 177px;
  height: 91px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const LogoTennisClinicMobile = styled.img`
  width: 216px;
  height: 60px;
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;

export const TextClinic = styled.label`
  font-size: 20px;
  font-weight: 400;
  color: white;
  font-family: "Inter", sans-serif;
`;

export const ContentInfos = styled.div`
  width: 636px;
  height: 412px;
  background-color: rgba(219, 12, 61, 0.62);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.9px);
  -webkit-backdrop-filter: blur(2.9px);
  display: flex;
  flex-direction: column;
  padding: 18px 18px;

  @media (max-width: 1150px) {
    width: 100%;
    margin-top: 30px;
  }

  @media (max-width: 550px) {
    height: 100%;
  }
`;

export const TextInfo = styled.label`
  font-size: 17px;
  font-family: "Inter", sans-serif;
  color: white;
`;

export const TextInfoTwo = styled.label`
  font-weight: 600;
  font-size: 19px;
  font-family: "Inter", sans-serif;
  color: white;
`;
