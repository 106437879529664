import {
  Container,
  ContainerButton,
  ContainerForm,
  Content,
  ContentInformations,
  Information,
  Subtitle,
  TableOne,
  Title,
} from "./styles";

import Table from "../../assets/comandatuba/tableOne.svg";
import TableMobile from "../../assets/comandatuba/tableOneMobile.svg";

import TableTwo from "../../assets/comandatuba/tableTwo.svg";
import TableTwoMobile from "../../assets/comandatuba/tableTwoMobile.svg";
import Form from "../campaign/form";
import { useState } from "react";
import ButtonWithIcon from "../campaign/button";

const Tables = ({ isCampaign }) => {
  const [modal, setModal] = useState(false);

  return (
    <Container id="tables">
      <Content>
        <TableOne>
          <Title>VALORES</Title>
          <Subtitle>HOSPEDAGEM + BALSA</Subtitle>

          <img src={Table} alt="tabela 1" />
          <img src={TableMobile} alt="table 1" />
          <ContentInformations>
            <Information>
              *CHD até 11 anos **Sujeito a disponibilidade
            </Information>
            <Information>
              Verifique mais fotos e detalhes das acomodações{" "}
              <a
                href="https://www.transamericacomandatuba.com.br/acomodacoes/"
                target="_blank"
                rel="noopener noreferrer"
              >
                clicando aqui
              </a>
            </Information>
          </ContentInformations>

          {isCampaign && (
            <>
              <ContainerButton>
                <ButtonWithIcon width={259} onClick={() => setModal(true)}>
                  QUERO VIVER O CAIOBA!
                </ButtonWithIcon>
              </ContainerButton>

              <ContainerForm style={{ display: modal ? "flex" : "none" }}>
                <Form modal={true} onClick={() => setModal(false)} />
              </ContainerForm>
            </>
          )}
        </TableOne>
        <TableOne>
          <Title>VALORES</Title>
          <Subtitle>AÉREO + HOSPEDAGEM + TRANSFER + BALSA</Subtitle>

          <img src={TableTwo} alt="tabela 2" />
          <img src={TableTwoMobile} alt="table 2" />

          <ContentInformations>
            <Information>
              *CHD até 11 anos **Sujeito a disponibilidade
            </Information>
          </ContentInformations>
          {isCampaign && (
            <>
              <ContainerButton>
                <ButtonWithIcon width={259} onClick={() => setModal(true)}>
                  QUERO VIVER O CAIOBA!
                </ButtonWithIcon>
              </ContainerButton>

              <ContainerForm style={{ display: modal ? "flex" : "none" }}>
                <Form modal={true} onClick={() => setModal(false)} />
              </ContainerForm>
            </>
          )}
        </TableOne>
      </Content>
    </Container>
  );
};

export default Tables;
